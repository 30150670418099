import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Obsessions from '../view/obsessions';
import '../style/style.scss';

const ObsessionsPage = props => (
  <Layout
    {...props}
    description={`Occasionally obsessed with what the 🌐 throws out`}
    title={`Obsessions`}
  >
    <Obsessions />
  </Layout>
);

export default ObsessionsPage;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: {
        frontmatter: { linkonly: { ne: true } }
        fields: { slug: { regex: "//obsession/.*/" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`;
