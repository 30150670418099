import React from 'react';
import { Link } from 'gatsby';

const PostSummary = props => {
  const { fields, frontmatter } = props;
  return (
    <div className="row">
      <div className="col mb-5">
        <h2 className="m-0 mb-3">
          <Link to={`${fields.slug}`}>{frontmatter.title}</Link>
        </h2>
        <p className="font-weight-light mb-1" style={{ fontSize: '0.8rem' }}>
          <span role="img" aria-label="date">
            📅
          </span>
          {` ${frontmatter.date} `}
        </p>
        <p className="lead text">{frontmatter.description}</p>
      </div>
    </div>
  );
};

const Posts = props => {
  return (
    <>
      <div className="row">
        <div className="col text-center ">
          <h1 className="m-0">
            Obsessions{' '}
            <span role="img" aria-label="loving">
              😍
            </span>
          </h1>
          <p className="font-weight-light m-0">
            Occasionally obsessed with what the{' '}
            <span role="img" aria-label="web">
              🌐
            </span>{' '}
            throws out
          </p>
        </div>
      </div>
      {props.data && props.data.allMdx.edges.length ? (
        <div className="mt-5">
          {props.data.allMdx.edges.map(post => {
            const { node } = post;
            return <PostSummary {...node} key={node.id} />;
          })}
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <h2 className="text-center pt-5 mt-5 text">
              Nothing yet! Stay tuned~
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export default Posts;
